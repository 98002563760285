<template lang="pug">
  .contact
    Breadcrumb(:current_path="{name: 'Contact', title: 'Contact'}")

    .container.contact__container
      h1.title_2.contact__title(v-html="$t('pages.contact.title')")

      form.contact__form
        .row.form__group
          .col-6.col-xs-12
            CustomInput(
              :placeholder="$t('form.name')"
              v-model="form.name"
            )
          .col-6.col-xs-12
            CustomInput(
              :placeholder="$t('form.email')"
              v-model="form.email"
            )
        .row.form__group
          .col-12
            CustomTextarea(
              :placeholder="$t('form.message')"
              v-model="form.message"
            )

        .row.form__group
          .col-12
            button(@click.prevent="send" v-html="$t('form.submit')").contact__form-button
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import CustomInput from '@/components/common/CustomInput'
import CustomTextarea from '@/components/common/CustomTextarea'
import FeedbackService from '@/services/feedback.service'

export default {
  name: 'Contact',
  components: { CustomTextarea, CustomInput, Breadcrumb },
  data: () => ({
    form: {
      name: '',
      email: '',
      message: ''
    }
  }),
  methods: {
    async send () {
      await FeedbackService.sendMessage(this.form)
          .then(response => console.log(response))
    }
  }
}
</script>

