class FeedbackRequestModel {
    name
    email
    message

    constructor (data) {
        this.name = data.name
        this.email = data.email
        this.message = data.message
    }
}

class FeedbackResponseModel {
    message

    constructor (data) {
        this.message = data.message
    }
}

export {
    FeedbackRequestModel,
    FeedbackResponseModel
}
