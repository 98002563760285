<template lang="pug">
  .breadcrumb
    .breadcrumb__container
      transition-group(tag="ul" name="fade").breadcrumb__list
        li.breadcrumb__item(v-for="(item, index) in breadcrumb" :key="'breadcrumb' + index")
          router-link(:to="$i18nRoute({ name: item.name, query: item.query })")
            template(v-if="item.name === 'Index'" )
              BaseIcon(name="home")
            template(v-else) {{ item.title }}
</template>

<script>
import BaseIcon from '@/components/common/BaseIcon'

export default {
  name: 'Breadcrumb',
  components: { BaseIcon },
  props: {
    current_path: {
      type: Object,
      required: true
    },
    previous_path: {
      type: Array,
      default: null
    },
  },
  data: () => ({
    breadcrumb: [{ name: 'Index', title: 'Index' }]
  }),
  watch: {
    $props: {
      handler () {
        this.setBreadcrumb()
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    toCapitalize (value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    setBreadcrumb () {
      this.breadcrumb = [{ name: 'Index', title: 'Index' }]
      if (this.previous_path !== null && Object.entries(this.previous_path).length) {
        this.previous_path.forEach(item => {
          this.breadcrumb.push({
            name: item.name,
            title: this.toCapitalize(item.title).replace('-', ' ')
          })
        })
      }

      if (Object.entries(this.current_path).length) {
        this.breadcrumb.push({
          name: this.current_path.name,
          title: this.toCapitalize(this.current_path.title)
        })
      }
    }
  },
  mounted () {
    this.setBreadcrumb()
  }
}
</script>
