import axiosApiInstance from '@/config/axios.config'

import { FeedbackResponseModel } from '@/models/feedback.model'

export default class FeedbackClient {
    static async sendMessage (data) {
        const response = await axiosApiInstance.post('/v1/util/send_bid', data)
        return new FeedbackResponseModel(response.data)
    }
}
