<template lang="pug">
  label.textarea
    p.textarea__label {{ label }}
    .textarea__field-wrapper
      textarea.textarea__field(
        :placeholder="placeholder"
        :value="value"
        @input="onInput"
      )
</template>

<script>
export default {
  name: 'CustomTextarea',
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
  },
  methods: {
    onInput (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
